.total-card {
  border: 2px solid #f0f0f0;
  border-radius: 30px;
  color: #858585;
  font-size: 15px;
  padding: 1.5rem;
  color: #858585;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
}

.total-card div {
  margin-bottom: 2rem;
}

.total-card div:last-child {
  margin-bottom: 0;
}

.total-card p {
  margin-bottom: 0;
}

/* Extra small devices (portrait phones, less than 576px)
No media query for `xs` since this is the default in Bootstrap */

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .total-card {
    margin-left: 40%;
  }
  .total-card div:nth-child(2),
  .total-card div:nth-child(3) {
    display: flex;
    justify-content: space-between;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}
