.status-justify {
  display: flex;
  justify-content: center;
}

.flat-card {
  border: 2px solid #f0f0f0;
  border-radius: 30px;
  color: #858585;
  font-size: 14px;
}

.flat-card-top {
  padding: 1.5rem;
  border-bottom: 2px solid #f0f0f0;
}

.flat-card-bottom {
  padding: 1.5rem;
}

.flat-card-bottom div {
  margin-bottom: 1rem;
}

.flat-card-bottom div:last-child {
  margin-bottom: 0;
}

.flat-card p:first-child {
  margin-bottom: 0;
}

.flat-card p:last-child {
  font-weight: 700;
  margin-bottom: 0;
}

.color-card {
  border: 2px solid #f0f0f0;
  border-radius: 30px;
  color: #858585;
  overflow: hidden;
  position: relative;
  font-size: 14px;
}

.color-card p:first-child {
  margin-bottom: 0;
}

.color-card p:last-child {
  font-weight: 700;
  margin-bottom: 0;
}

.color-card-top {
  color: white;
  padding: 1.5rem;
}

.color-card-middle {
  padding: 1rem 1.5rem;
}

.color-card-middle div {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 0;
}

.color-card-bottom {
  background: #6789ca;
  color: white;
  font-weight: 700;
  text-align: center;
  padding: 1.5rem;
  width: 100%;
  border: none;
}

.color-card-bottom:focus {
  outline: none;
}

.color-card-bottom:disabled {
  background: grey;
}

img.card-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: -1;
}

/* Extra small devices (portrait phones, less than 576px)
No media query for `xs` since this is the default in Bootstrap */

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .status-justify {
    justify-content: flex-end;
    margin-top: 0;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .color-card-middle {
    padding: 4rem 1.5rem;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}
