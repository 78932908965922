.table {
  text-align: center;
  margin-bottom: 0;
  color: #858585;
}

th,
td {
  vertical-align: middle !important;
  font-size: 12px !important;
}

th {
  font-weight: 700 !important;
}

td {
  font-weight: 400 !important;
}

.action-button {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #006881;
}
