.rootButton {
  background: linear-gradient(
    180deg,
    rgba(0, 104, 129, 1) 0%,
    rgba(67, 186, 148, 1) 120%
  );
  box-shadow: none;
  border: none;
  border-radius: 30px;
  color: white;
  padding: 0.5rem 2rem;
  font-size: 18px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rootButton:focus {
  outline: none;
}

.secondary {
  background: linear-gradient(
    180deg,
    rgba(252, 227, 138, 1) 0%,
    rgba(243, 129, 129, 1) 90%
  );
}

.small {
  font-size: 14px;
  padding: 0.25rem 1rem;
}
