/* override input styles */
input[type="date"]::-webkit-calendar-picker-indicator {
  background: url("./icons/calendar.svg") no-repeat center;
  height: 100%;
}
input input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* override pagination styles */
.pagination {
  font-weight: 700;
  margin-bottom: 0;
}
.pagination .page-item.active .page-link {
  background: linear-gradient(
    180deg,
    rgba(0, 104, 129, 1) 0%,
    rgba(67, 186, 148, 1) 120%
  );
}
