.root {
  background: linear-gradient(178.18deg, #006881 -13.56%, #43ba94 158.3%);
  height: 250px;
  border-radius: 20px;
  position: relative;
  font-family: "Poppins", sans-serif;
  color: white;
}

.root h1 {
  font-weight: 700;
}

.glass {
  height: 100%;
  width: 100%;
  border-radius: 20px;
  position: absolute;
  overflow: hidden;
  padding: 3rem;
}

img.man {
  display: none;
}

img.mask {
  position: absolute;
  bottom: 0;
  height: 120px;
}

img.flower {
  display: none;
}

/* Extra small devices (portrait phones, less than 576px)
No media query for `xs` since this is the default in Bootstrap */

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  img.man {
    display: block;
    position: absolute;
    right: 0;
    bottom: 0;
    height: 330px;
  }
  img.flower {
    display: block;
    position: absolute;
    bottom: 0;
    left: -25px;
    height: 75px;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .root {
    height: 350px;
  }
  img.man {
    height: 460px;
  }
  img.flower {
    left: -45px;
    height: 140px;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}
