.total-invoice {
  font-weight: 700;
  color: #858585;
  font-size: 15px;
  font-family: "Poppins", sans-serif;
}

.total-invoice div {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.total-invoice p {
  margin-bottom: 0;
}

/* Extra small devices (portrait phones, less than 576px)
No media query for `xs` since this is the default in Bootstrap */

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .total-invoice {
    padding-left: 40%;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}
