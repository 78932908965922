body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

main {
  font-family: "Poppins", sans-serif;
}

label {
  font-size: 14px;
}

.text-grey {
  color: #858585;
}

main {
  padding: 2rem 0;
}

.create-row-gap {
  row-gap: 1rem;
}

.link {
  cursor: pointer;
}

.link-text {
  font-weight: 700;
  font-size: 18px;
  text-align: center;
}

.link-text:hover {
  color: #3c3c3c;
}

.divider {
  border: 1px solid #00688180;
  margin: 0.5rem auto 0;
  width: 100px;
}

/* Extra small devices (portrait phones, less than 576px)
No media query for `xs` since this is the default in Bootstrap */

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  main {
    padding: 5rem 0;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}
